@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CaskaydiaCove";
  src: url("./fonts/caskaydiacove/CaskaydiaCove-Regular.ttf") format("truetype"),
    url("./fonts/caskaydiacove/CaskaydiaCove-Light.ttf") format("truetype"),
    url("./fonts/caskaydiacove/CaskaydiaCove-ExtraLight.ttf") format("truetype"),
    url("./fonts/caskaydiacove/CaskaydiaCove-Medium.ttf") format("truetype"),
    url("./fonts/caskaydiacove/CaskaydiaCove-SemiBold.ttf") format("truetype"),
    url("./fonts/caskaydiacove/CaskaydiaCove-Bold.ttf") format("truetype");
}

@layer base {
  html {
    @apply scroll-smooth;
  }
}

body {
  @apply bg-gradient-to-br from-sky-600 to-orange-600 text-white relative;
  @apply bg-image1 bg-cover bg-center;
  @apply font-geist;
  font-variant-ligatures: none;

}



.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
